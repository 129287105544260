import React, { useEffect, useState } from "react";
import NavBar from "Components/NavBar";
import data from "../../DUMMY_DATA/applicantdata.json";
import FilterPanel from "Components/FilterPanel";
import SearchPanel from "Components/SearchPanel";
import { useSelector, useDispatch } from "react-redux";
import ApplicantCard from "Components/ApplicantCard";

const ViewApplicants = () => {
  const dispatch = useDispatch();
  const [filterObject, setFilterObject] = useState({
    location: "",
    remote: false,
    keywords: [],
    related: "",
    type: "",
    match: "",
  });

  const getFilteredItems = (query, items) => {
    if (!query) {
      return items;
    }
    return items.filter((song) => song.name.includes(query));
  };

  // const { jobList } = useSelector((state) => state.jobs);

  // useEffect(() => {
  //   dispatch(loadJobs(data));
  //   dispatch(loadKeywords(keywords));
  // }, []);

  // useEffect(() => {
  //   data.map((applicant) => {
  //     const filteredObj = Object.keys(applicant).reduce((p, c) => {
  //       if (applicant[c]) p[c] = applicant[c];
  //       return p;
  //     }, {});
  //     console.log("filtered", filteredObj);
  //   });
  // }, [filterObject]);

  return (
    <>
      <h1>MINRC applicant Board</h1>
      <div className="job-wrap">
        <SearchPanel getFilteredItems={getFilteredItems} />
        <div className="job-view">
          <div className="job-container">
            {data.map((applicant) => {
              return <ApplicantCard key={applicant.id} applicant={applicant} />;
            })}
          </div>
          <div className="filter-container">
            <FilterPanel
              filterObject={filterObject}
              setFilterObject={setFilterObject}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewApplicants;
