import React from "react";
import { Link } from "react-router-dom";
import "./applicant.css";
import { Button } from "react-bootstrap";
import * as BsIcons from "react-icons/bs";

const Applicant = ({ applicant }) => {
  console.log(applicant);
  return (
    <>
      <div className="applicant-view-container">
        <h2>Applicant {applicant.id}</h2>
        <h3>Email: {applicant.email}</h3>
        <h3>
          Skills:
          <span className="skill-container">
            {applicant.skills.map((skill) => (
              <span className="applicant-skill">{skill}</span>
            ))}
          </span>
        </h3>
        <h3>Education: {applicant.education}</h3>
        <div className="button-container">
          <Button className="applicant-button warning" variant="warning">
            Decline
          </Button>
          <Button className="applicant-button " variant="success">
            Contact
          </Button>
        </div>
        <Link className="return-btn" to={"/agency-home"}>
          <BsIcons.BsArrowLeft /> Back to Dashboardoard
        </Link>
      </div>
    </>
  );
};

export default Applicant;
