import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import { ReactComponent as Logo } from "./logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseChimney } from "@fortawesome/free-solid-svg-icons";
import "./navbar.css";

const home = <FontAwesomeIcon color="#012c3a" icon={faHouseChimney} />;

const SeafwaNav = () => {
  const [sidebar, setSidebar] = useState(false);

  const location = useLocation();

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div className="seafwa-container">
      <div className="seafwa-logo-container">
        <Logo className="seafwa-logo" />
      </div>
      <div className="seafwa-nav-container">
        <nav className="seafwa-navbar">
          <Link to="https://seafwa.org/" className="seafwa-menu-item">
            {home}
          </Link>
          <Link
            to="https://seafwa.org/about"
            className="seafwa-menu-item dropdown"
          >
            About
          </Link>
          <Link
            to="https://seafwa.org/news"
            className="seafwa-menu-item dropdown"
          >
            News
          </Link>
          <Link
            to="https://seafwa.org/events"
            className="seafwa-menu-item dropdown"
          >
            Events
          </Link>
          <Link
            to="https://seafwa.org/journal"
            className="seafwa-menu-item dropdown"
          >
            Journal
          </Link>
          <Link
            to="https://seafwa.org/awards"
            className="seafwa-menu-item dropdown"
          >
            Awards
          </Link>
          <Link
            to="https://seafwa.org/resources"
            className="seafwa-menu-item dropdown"
          >
            Resources
          </Link>
          <Link
            to="https://seafwa.org/committees"
            className="seafwa-menu-item dropdown"
          >
            Committees
          </Link>
          <Link
            to="https://seafwa.org/initiatives"
            className="seafwa-menu-item"
          >
            Initiatives
          </Link>
          <Link
            to="https://seafwa.org/foundation"
            className="seafwa-menu-item dropdown"
          >
            Foundation
          </Link>
        </nav>
      </div>
      <div className="minrc-title-container">
        <h1>Minorities in Natural Resources Conservation Committe</h1>
      </div>
      <div className="minrc-menu-container">
        <Link
          to="https://seafwa.org/committee/minrc"
          className="minrc-menu-item"
        >
          Overview
        </Link>
        <Link
          to="https://seafwa.org/committee/minrc/members"
          className="minrc-menu-item"
        >
          Members
        </Link>
        <Link
          to="https://seafwa.org/committee/minrc/documents"
          className="minrc-menu-item"
        >
          Documents
        </Link>
        <Link
          to="https://seafwa.org/committee/minrc/events"
          className="minrc-menu-item"
        >
          Events
        </Link>
        <span className="minrc-menu-item active">Jobs</span>
      </div>
    </div>
  );
};

export default SeafwaNav;
