import Keyword from "Components/Keyword";
import React from "react";
import "./jobpost.css";
import * as MdIcons from "react-icons/md";
import { Link } from "react-router-dom";

const JobPost = ({ job }) => {
  console.log(job);
  return (
    <div className="jobpost-container">
      <Link className="job-btn success sticky" to={"/apply-success"}>
        I'm Interested
      </Link>
      <h1>{job.title}</h1>
      <p>Applications due: {job.dueDate}</p>
      <h3>Location:</h3>
      <span className="location-pin">
        {job.locations.map((location, index) => {
          return (
            <span key={index}>
              <MdIcons.MdLocationPin />{" "}
              {typeof location != "object" ? location : location.value}
            </span>
          );
        })}
      </span>
      <h3>Agency:</h3>
      <p>{job.agency}</p>
      <h3>Salary</h3>
      <p>{job.salary}</p>
      <h3>Estimated position dates: </h3>
      <p>
        {job.start} - {job.end}
      </p>
      {!!job.type && (
        <div className="job-info-container">
          <h3>Job Type:</h3>
          <p>{job.type}</p>
        </div>
      )}
      <h3>Description:</h3>
      {job.summary.map((paragraph, index) => {
        return (
          <p key={index} className="job-description">
            {paragraph}
          </p>
        );
      })}

      <h3>Areas of Interest:</h3>
      <ul>
        {job.keywords.map((keyword, index) => {
          const keywordVal =
            typeof keyword != "object" ? keyword : keyword.value;
          return <Keyword keyword={keywordVal} key={index} />;
        })}
      </ul>
      <br />
      <h3>Qualifications:</h3>
      <ul>
        {job.qualifications.map((qualifications, index) => {
          return <li key={index}>{qualifications}</li>;
        })}
      </ul>
      {!!job.responsibilities[0] && (
        <div className="job-info-container">
          <h3>Responsibilities:</h3>
          <ul>
            {job.responsibilities.map((responsibilities, index) => {
              return <li key={index}>{responsibilities}</li>;
            })}
          </ul>
        </div>
      )}
      {!!job.benefits && (
        <div className="job-info-container">
          <h3>Benefits:</h3>
          <ul>
            {job.benefits.map((benefits, index) => {
              return <li key={index}>{benefits}</li>;
            })}
          </ul>
        </div>
      )}
      {!!job.website && (
        <div className="job-info-container">
          <h4>Get more information here:</h4>
          <a href={job.website}>
            <p>{job.website}</p>
          </a>
        </div>
      )}
    </div>
  );
};

export default JobPost;
