import { configureStore } from "@reduxjs/toolkit";
import jobReducer from "../Redux/Jobs/jobSlice";
import userReducer from "../Redux/User/userSlice";

//Store
export const store = configureStore({
  reducer: {
    jobs: jobReducer,
    user: userReducer,
  },
});
