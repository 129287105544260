import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Main from "Views/Main/Main";
import ApplicantHome from "Views/Applicant/ApplicantHome";
import AgencyHome from "Views/Agency/AgencyHome";
import AgencySignup from "Views/Agency/AgencySignup";
import ApplicantSignup from "Views/Applicant/ApplicantSignup";
import data from "./DUMMY_DATA/jobdata.json";
import JobPost from "Views/Applicant/Job/JobPost";
import NavBar from "Components/NavBar";
import ApplySuccess from "./Views/Applicant/ApplySuccess";
import Applicant from "Views/Agency/Applicant";
import AddJob from "Views/Agency/Job/AddJob";
import ViewApplicants from "Views/Agency/ViewApplicants";
import SeafwaNav from "Components/SeafwaNav";
import SeafwaFooter from "Components/SeafwaFooter";
import { useSelector } from "react-redux";
import Contact from "Views/Contact";
import ApplicantProfile from "Views/Applicant/ApplicantProfile";
import AgencyProfile from "Views/Agency/AgencyProfile";
import JobPostSuccess from "Views/Agency/Job/JobPostSuccess";

function App() {
  const location = useLocation();

  const jobs = useSelector((state) => state.jobs);

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  return (
    <div className="App">
      <SeafwaNav />
      <NavBar />
      <Routes>
        <Route path="/" element={<Main />}></Route>
        <Route path="/applicant-home" element={<ApplicantHome />}></Route>
        <Route path="/agency-home" element={<AgencyHome />}></Route>
        <Route path="/new-applicant" element={<ApplicantSignup />}></Route>
        <Route path="/new-agency" element={<AgencySignup />}></Route>
        <Route path="/apply-success" element={<ApplySuccess />}></Route>
        <Route path="/add-job" element={<AddJob />}></Route>
        <Route path="/view-applicants" element={<ViewApplicants />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/applicant-profile" element={<ApplicantProfile />}></Route>
        <Route path="/agency-profile" element={<AgencyProfile />}></Route>
        <Route path="/job-post-success" element={<JobPostSuccess />}></Route>
        {jobs.map((job) => {
          const url = slugify(job.title + job.id);
          return (
            <Route
              key={job.id}
              path={`/${url}`}
              element={<JobPost job={job} />}
            ></Route>
          );
        })}
        {data.map((job) => {
          return job.interest.map((applicant) => {
            return (
              <Route
                key={applicant.id}
                path={`/applicant-${applicant.id}`}
                element={<Applicant applicant={applicant} />}
              ></Route>
            );
          });
        })}
        {data.map((job) => {
          const url = slugify(job.title + job.id);
          return (
            <Route
              key={job.id}
              path={`/view-${url}`}
              element={<JobPost job={job} />}
            ></Route>
          );
        })}
        {data.map((job) => {
          const url = slugify(job.title + job.id);
          return (
            <Route
              key={job.id}
              path={`/edit-${url}`}
              element={<JobPost job={job} />}
            ></Route>
          );
        })}
        {/* Default route to catch non-existent routes */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <SeafwaFooter />
    </div>
  );
}

export default App;
