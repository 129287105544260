import React from "react";
import { Table } from "react-bootstrap";
import "./agencyhome.css";
import PostedPositions from "./PostedPositions";

import data from "../../DUMMY_DATA/jobdata.json";
import { Link } from "react-router-dom";

const AgencyHome = () => {
  return (
    <>
      <h1 className="agency-h1">"My Agency" Dashboard</h1>

      <div className="table-container">
        <Table striped bordered className="agency-table">
          <thead>
            <tr>
              <th>Positions Posted</th>
              <th>Views</th>
              <th>Interested Applicants</th>
            </tr>
          </thead>
          <tbody>
            {data.map((job) => {
              return <PostedPositions key={job.id} job={job} />;
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default AgencyHome;
