import React, { useEffect, useState } from "react";
import "./ApplicantHome.css";
import aois from "../../DUMMY_DATA/keywords.json";
import JobCard from "Components/JobCard";
import FilterPanel from "Components/FilterPanel";
import SearchPanel from "Components/SearchPanel";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const ApplicantHome = () => {
  const dispatch = useDispatch();
  const [filterObject, setFilterObject] = useState({
    location: "",
    remote: false,
    aois: [],
    related: "",
    type: "",
    match: "",
  });
  const navigate = useNavigate();
  const jobs = useSelector((state) => state.jobs);
  const user = useSelector((state) => state.user);
  const [rating, setRating] = useState(0);
  const [filteredJobs, setFilteredJobs] = useState(jobs);
  const [changedFilter, setChangedFilter] = useState("");
  const [searchText, setSearchText] = useState("");

  const getFilteredItems = (query, items) => {
    if (!query) {
      return items;
    }
    return items.filter((song) => song.name.includes(query));
  };

  if (!user.loggedIn) navigate("/");

  useEffect(() => {
    setFilteredJobs(
      jobs.filter((job) => {
        if (
          !filterObject.location &&
          !filterObject.remote &&
          !filterObject.type &&
          filterObject.aois.length === 0 &&
          rating === 0 &&
          searchText.length === 0
        )
          return true;

        if (filterObject.type) {
          if (!job.type.toLowerCase().includes(filterObject.type)) {
            return false;
          }
        }

        if (filterObject.remote) {
          let doesContain = false;
          job.locations.forEach((location) => {
            if (location.value === "Remote") {
              doesContain = true;
            }
          });
          if (!doesContain) return false;
        }

        if (filterObject.location) {
          let doesContain = false;
          job.locations.forEach((location) => {
            if (location.value.includes(filterObject.location)) {
              doesContain = true;
            }
          });
          if (!doesContain) return false;
        }

        if (filterObject.aois.length > 0) {
          let doesContain = false;
          filterObject.aois.forEach((filterAoi) => {
            job.keywords.forEach((jobAoi) => {
              console.log(jobAoi);
              if (jobAoi.value)
                if (
                  jobAoi.value
                    .toLowerCase()
                    .includes(filterAoi.value.toLowerCase())
                ) {
                  doesContain = true;
                }
            });
          });
          if (!doesContain) return false;
        }

        if (rating > 0) {
          if (job.match < rating) return false;
        }

        if (searchText.length > 0) {
          let doesContain = false;
          if (
            job.title.toLowerCase().includes(searchText.toLowerCase()) ||
            job.agency.toLowerCase().includes(searchText.toLowerCase()) ||
            job.type.toLowerCase().includes(searchText.toLowerCase()) ||
            job.locations.forEach((location) => {
              if (
                location.value.toLowerCase().includes(searchText.toLowerCase())
              ) {
                doesContain = true;
              }
              if (!doesContain) return false;
            })
          )
            doesContain = true;

          if (!doesContain) return false;
        }

        return true;
      })
    );
  }, [filterObject, searchText]);

  return user.loggedIn ? (
    <>
      <h1>MINRC Job Board</h1>
      <div className="job-wrap">
        <SearchPanel
          filteredJobs={filteredJobs}
          setFilteredJobs={setFilteredJobs}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <div className="job-view">
          <div className="job-container">
            {filteredJobs.map((job) => {
              return <JobCard key={job.id} job={job} />;
            })}
          </div>
          <div className="filter-container">
            <FilterPanel
              filterObject={filterObject}
              setFilterObject={setFilterObject}
              setChangedFilter={setChangedFilter}
              rating={rating}
              setRating={setRating}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div> YOU'RE NOT LOGGED IN! </div>
  );
};

export default ApplicantHome;
