import React, { useState } from "react";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./postedPositions.css";

const PostedPositions = ({ job }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleCard() {
    setIsExpanded(!isExpanded);
  }

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  const url = slugify(job.title);

  return (
    <tr>
      <td>
        <h3>{job.title}</h3>
        <Link
          to={`/view-${url + job.id}`}
          className="posted-btn view-job-button"
        >
          <BsIcons.BsEye />
        </Link>
        <Link
          to={`/edit-${url + job.id}`}
          className="posted-btn edit-job-button"
        >
          <BsIcons.BsPencilFill />
        </Link>
        <Link to="#" className="posted-btn remove-job-button">
          <BsIcons.BsTrash />
        </Link>
      </td>
      <td>{job.views}</td>
      <td className="applicant-col">
        <div className="applicant-container">
          {job.interest.map((applicant, index) => {
            if (applicant === "None Yet") {
              return "None Yet";
            } else
              return (
                <Link
                  className="applicant-btn"
                  to={`/applicant-${applicant.id}`}
                >
                  Applicant {applicant.id}
                </Link>
              );
          })}
        </div>
      </td>
    </tr>
  );
};

export default PostedPositions;
