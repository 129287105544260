import React, { useState, useEffect } from "react";
import "./filterpanel.css";
import { useSelector } from "react-redux";
import { stateOptions } from "./stateOptions";
import fieldOptions from "../DUMMY_DATA/fieldOptions";
import { positionOptions } from "./positionOptions";
import Select from "react-select";
import { MultiSelect } from "./MultiSelect";
import StarRating from "./StarRating";

import aois from "../DUMMY_DATA/keywords.json";

const FilterPanel = ({
  filterObject,
  setFilterObject,
  setChangedFilter,
  rating,
  setRating,
}) => {
  const [hover, setHover] = useState(0);

  useEffect(() => {
    setFilterObject({ ...filterObject, matchRate: rating });
  }, [rating]);

  return (
    <div className="filter-panel">
      <h2>Filters</h2>
      <h4>Match:</h4>
      <StarRating
        rating={rating}
        setRating={setRating}
        hover={hover}
        setHover={setHover}
        setChangedFilter={setChangedFilter}
      />
      <hr />
      <h4>Location:</h4>
      <Select
        placeholder="Select a state"
        options={stateOptions}
        onChange={(e) => {
          setChangedFilter("location");
          setFilterObject({ ...filterObject, location: e.value });
        }}
      />
      <label>
        <input
          id="remote-input"
          type="checkbox"
          checked={filterObject.remote}
          onChange={() => {
            setChangedFilter("remote");
            setFilterObject({ ...filterObject, remote: !filterObject.remote });
          }}
        />
        Remote
      </label>
      <hr />
      <h4>Areas of Interest:</h4>
      <MultiSelect
        placeholder="Select areas of interest"
        options={aois}
        value={filterObject.aois}
        onChange={(selectedOption) => {
          setChangedFilter("aois");
          let state;
          if (selectedOption) {
            state = selectedOption.map((selected) => ({
              ...selected,
            }));
          } else {
            state = null;
          }
          setFilterObject({ ...filterObject, aois: state });
        }}
      />
      <hr />
      {/* <h4>Related Field:</h4>
      <Select
        placeholder="Select a field"
        options={fieldOptions}
        onChange={(e) => {
          setChangedFilter("field");
          setFilterObject({ ...filterObject, related: e.value });
        }}
      />
      <hr /> */}
      <h4>Position Type:</h4>
      <Select
        placeholder="Select a type"
        options={positionOptions}
        onChange={(e) => {
          setChangedFilter("type");
          setFilterObject({ ...filterObject, type: e.value });
        }}
      />
    </div>
  );
};

export default FilterPanel;
