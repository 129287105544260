import React, { useState } from "react";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import "./jobcard.css";
import { Link } from "react-router-dom";

const JobCard = ({ job }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  function toggleCard() {
    setIsExpanded(!isExpanded);
  }

  const slugify = (str) =>
    str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, "")
      .replace(/[\s_-]+/g, "-")
      .replace(/^-+|-+$/g, "");

  function MatchLoop({ times }) {
    const keys = [...Array(times).keys()];
    return (
      <>
        {keys.map((item) => (
          <span key={item} className="job-star">
            &#9733;
          </span>
        ))}
      </>
    );
  }

  return (
    <div className={isExpanded ? "show-full job-card" : "job-card"}>
      <div className="inner-job-container">
        <p className="due-date">
          Applications due by {job.dueDate}
          <span style={{ float: "right" }}>
            <MatchLoop times={job.match} />
          </span>
        </p>

        <h2 className="job-title">{job.title}</h2>
        {job.summary.map((paragraph, index) => {
          return <p key={index}>{paragraph}</p>;
        })}
        <span className="location-pin">
          {job.locations.map((location, index) => {
            return (
              <span key={index}>
                <MdIcons.MdLocationPin /> {location.value}
              </span>
            );
          })}
        </span>
        <div className="job-keyword-cont">
          {job.keywords.map((keyword, index) => {
            return (
              <span className="job-keyword" key={index}>
                {typeof keyword != "object" ? keyword : keyword.label}
              </span>
            );
          })}
        </div>
        <div className="card-btn-container">
          <Link className="job-btn" to={`/${slugify(job.title + job.id)}`}>
            Learn More
          </Link>
          <Link className="job-btn success" to={"/apply-success"}>
            I'm Interested
          </Link>
        </div>
      </div>
      <span
        onClick={toggleCard}
        className={isExpanded ? "expanded chevron" : "chevron"}
        style={{ fontSize: "24px", justifySelf: "flex-end" }}
      >
        <BsIcons.BsChevronCompactDown />
      </span>
    </div>
  );
};

export default JobCard;
